@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300");

body {
  font-family: "Montserrat";
}

.btn-primary {
  background-color: #00C3A5;
  border-color: #00C3A5;
  color: white;
}
.btn-primary:hover {
  background-color: #9b9b9b;
  border-color: #9b9b9b;
  color: white;
}
.btn-primary:active:focus {
  background-color: black;
  border-color: black;
  color: white;
}

.btn-secondary {
  background-color: #007D6A;
  border-color: #007D6A;
  color: white;
}
.btn-secondary:hover {
  background-color: #9b9b9b;
  border-color: #9b9b9b;
  color: white;
}
.btn-secondary:active:focus {
  background-color: black;
  border-color: black;
  color: white;
}
